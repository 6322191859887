import React from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

export const query = graphql`
	query {
		site {
			siteMetadata {
				env
			}
		}
		allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
			totalCount
			distinct(field: frontmatter___tags)
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "DD MMMM, YYYY")
						tags
						pic
					}
					fields {
						slug
					}
					excerpt
				}
			}
		}
	}`;

/* TODO: 
	1. Pagination implementation
	2. Navigate from one post to another
	3. Proper tags/categories implementation

	{
		allMarkdownRemark( sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { tags: { eq: "test1" } } })
		{
			totalCount
			edges {
			node {
				id
				frontmatter {
					title
					date(formatString: "DD MMMM, YYYY")
					tags
				}
				fields {
					slug
				}
				excerpt
			}
		}
	}
*/

export default function Blogs({ data }) {

	const [tag, setTag] = useQueryParam('tag', StringParam);
	// console.log('Selected Tag:', tag);

	return (
		<Layout title="Blogs">
			<div>
				<h4 className="post-count px-2">{data.allMarkdownRemark.totalCount} Posts</h4>

				<hr></hr>
				{data.allMarkdownRemark.distinct ?
					<ul className="list-inline px-2 mb-2">
						<li className={`list-inline-item distinct post-tags ${(tag === 'All' || !tag) ? 'selected' : null}`} onClick={() => setTag('All')}>All</li>
						{data.allMarkdownRemark.distinct.map((tagSel) => (
							<li key={tagSel} className={`list-inline-item distinct post-tags ${tag === tagSel ? 'selected' : null}`}
								onClick={() => setTag(tagSel)}>{tagSel}</li>
						))}
					</ul> : null}
				<hr></hr>

				{data.allMarkdownRemark.edges.map(({ node }) => {
					return (
						<div key={node.id}
							className={(tag && tag !== 'All' && node.frontmatter.tags.indexOf(tag)) === -1 ? 'd-none' : null}>
							<Link className="post-wrapper" to={node.fields.slug}>
								<h3 className="post-title p-2 mt-3 mb-0">
									{node.frontmatter.title}{" "}
									<span className="post-date"> — {node.frontmatter.date} </span>
								</h3>
							</Link>

							{node.frontmatter.tags ?
								<ul className="list-inline px-2 mb-2">
									{node.frontmatter.tags.map((data, index) => (
										<li key={index} className="list-inline-item post-tags">{data}</li>
									))}
								</ul> : null}

							<p className="post-excerpt px-2">{node.excerpt}</p>
						</div>);
				})}

			</div>
		</Layout>
	);
};
